import { Box, Button, Tabs as MaterialTabs, Tab } from '@mui/material'
import { useScreenDetector } from '../../hooks/useScreenDetector'

const Tabs = ({ activeTab, tabs, onChangeTab }) => {
    const { isMobile } = useScreenDetector()

    const MobileTabs = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}>
            {tabs.map((tab, index) => {
                const isActive = index === activeTab
                return (
                    <Button
                        key={index}
                        variant={isActive ? 'contained' : 'outlined'}
                        color={isActive ? 'secondary' : 'primary'}
                        sx={{
                            borderRadius: '16px',
                            height: 'fit-content',
                            color: isActive ? '#ffffff' : '#40C5E8',
                            display: 'grid',
                            textTransform: 'none'
                        }}
                        onClick={onChangeTab(index)}>
                        {tab.name}
                    </Button>
                )
            })}
        </Box>
    )

    const DesktopTabs = () => (
        <MaterialTabs
            value={activeTab}
            onChange={onChangeTab()}
            textColor="primary"
            indicatorColor="secondary"
            TabIndicatorProps={{
                sx: {
                    display: 'none'
                }
            }}>
            {tabs.map((tab, index) => (
                <Tab
                    key={tab.name}
                    value={index}
                    label={tab.name}
                    TabIndicatorProps={{
                        sx: {
                            width: 2
                        }
                    }}
                    sx={{
                        fontSize: 20,
                        fontFamily: 'Poppins',
                        lineHeight: 1.5,
                        fontWeight: 400,
                        textTransform: 'none'
                    }}
                />
            ))}
        </MaterialTabs>
    )

    return isMobile ? <MobileTabs /> : <DesktopTabs />
}

export default Tabs
