import { Container, Typography } from "@mui/material";
import content from "../../components/MockData/blog";

const Blog = () => {
    return (
        <Container sx={{justifyContent: "center", textAlign: 'center'}}>
            {Object.keys(content).map(key => {
                return (
                    <>
                        {key == 'title' && <Typography
                            sx={{py: '10px'}}
                            variant={content[key].variant}
                            content={content[key].variant}
                        >{content[key].value}</Typography>}
                        {key == 'paraghrap' && <Typography
                            sx={{py: '10px'}}
                            variant="body1"
                            content="p"
                        >{content[key]}</Typography>}
                        {key == 'image' && <img
                            src={content[key].src}
                            alt={content[key].alt}
                            width={content[key].width}
                            height={content[key].height}
                        />}
                    </>
                )
            })}
        </Container>
    )
}

export default Blog;