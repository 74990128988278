import React from "react"
import {
    Box,
    Button,
    Container,
    Collapse,
    Divider,
    Link,
} from "@mui/material"
import {ExpandLess, ExpandMore} from "@mui/icons-material"
import SocialLinksSection from "./SocialLinksSection"
import PayMethodsSection from "./PayMethodsSection"
import { ReactComponent as TukTukLogo } from "../../assets/images/tuktuk-logo.svg"

const Footer = () => {
    const [expandSections, setExpandSection] = React.useState([
        {
            expanded: true,
            name: "Destinos"
        },
        {
            expanded: false,
            name: "Guías de viaje"
        },
        {
            expanded: false,
            name: "Especiales"
        },
        {
            expanded: false,
            name: "Conócenos"
        }
    ])

    const onColapseSection = (index) => () => {
        const updatedSections = expandSections;
        updatedSections[index].expanded = !expandSections[index].expanded
        setExpandSection([...updatedSections])
    }

    return (
        <Box sx={{
            backgroundColor: "#F0F0F1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            pt: "6rem",
            pb: "5rem",
        }}>
            <TukTukLogo />
            <SocialLinksSection />
            {
                expandSections.map((section, index) => (
                    <Container sx={{mb: "1rem"}} key={section.name}>
                        <Button
                            endIcon={section.expanded ? <ExpandMore /> : <ExpandLess />}
                            variant="text"
                            sx={{
                                color: "#223F7F",
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontWeight: 700,
                                width: "100%",
                            }}
                            onClick={onColapseSection(index)}
                        >
                            {section.name}
                        </Button>
                        <Divider aria-hidden="true" color='secondary' sx={{ height: 2}}/>
                        <Collapse
                            in={expandSections[index].expanded}
                            timeout='auto'
                            unmountOnExit
                        >
                            <Box
                                sx={{
                                    color: "#666B6E",
                                    display: "flex",
                                    flexDirection: "column",
                                    fontFamily: "Inter",
                                    fontSize: "1rem",
                                    textAlign: "center",
                                }}
                            >
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum dolor</Link>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </Box>
                        </Collapse>
                    </Container>
                ))
            }
            <PayMethodsSection />
            <Container sx={{
                display: "flex", 
                flexWrap: "wrap",
                color: "#666B6E",
                fontSize: "14px",
            }} >
                <Link sx={{textWrap: "nowrap", mr: "1rem", mb: "1rem"}} color="grayText" underline='none'>Políticas de uso</Link>
                <Link sx={{textWrap: "nowrap"}} color="grayText" underline='none'>Política de Privacidad</Link>
                <Link sx={{textWrap: "nowrap"}} color="grayText" underline='none'>Política de cookies</Link>
            </Container>
        </Box>
    )
}

export default Footer;
