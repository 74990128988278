const gridImagesContent = {
    title: 'Nuestros destinos favoritos',
    text: 'Lorem ipsum dolor sit amet consectetur lectus tincidunt.',
    itemData: [
        {
            img: require('../../assets/images/granada.jpg'),
            title: 'Granada',
            cols: 1,
            rating: 3
        },
        {
            img: require('../../assets/images/barcelona.jpg'),
            title: 'Barcelona',
            cols: 1,
            rating: 4
        },
        {
            img: require('../../assets/images/madrid.jpg'),
            title: 'Madrid',
            cols: 2,
            rating: 5
        },
        {
            img: require('../../assets/images/sevilla.jpg'),
            title: 'Sevilla',
            cols: 1,
            rating: 3
        },
        {
            img: require('../../assets/images/malaga.jpg'),
            title: 'Malaga',
            cols: 2,
            rating: 5
        },
        {
            img: require('../../assets/images/lisboa.jpg'),
            title: 'Lisboa',
            cols: 1,
            rating: 4
        }
    ]
}

export default gridImagesContent;