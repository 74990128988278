import { Container, Typography } from '@mui/material'
import SearchInput from '../SearchInput'
import bgImage from '../../assets/images/hero.png'
import BlurImageWrapper from '../BlurImageWrapper'
import './styles.css'

const Hero = () => {
    return (
        <>
            <BlurImageWrapper imageUrl={bgImage} renderVector>
                <Container
                    sx={{
                        width: '100%',
                        height: 468,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: '4rem'
                    }}>
                    <Typography
                        variant="h3"
                        color="primary.contrastText"
                        sx={{
                            fontWeight: 'bold',
                            mb: '1rem'
                        }}>
                        ¿Empezamos?
                    </Typography>
                    <Typography
                        variant="body"
                        color="primary.contrastText"
                        sx={{ px: '15%' }}>
                        Tour privados a medida en más de 400 destinos
                    </Typography>
                    <Typography
                        variant="body"
                        color="primary.contrastText"
                        sx={{ mb: { xs: '4rem', md: '2rem' } }}>
                        ¡Elige el que más se adapte a tí!
                    </Typography>
                    <SearchInput />
                </Container>
            </BlurImageWrapper>
            <div className="hero-bottom">
                <Typography
                    color="primary.contrastText"
                    variant="subtitle2"
                    sx={{ mb: '1rem' }}>
                    Tours privados a medida
                </Typography>
                <Typography
                    variant="body1"
                    color="primary.contrastText"
                    sx={{ textAlign: 'center', maxWidth: 1024 }}>
                    Lorem ipsum dolor sit amet consectetur. Tristique nec
                    malesuada tempor faucibus. Facilisi mi et odio enim
                    consequat metus.
                </Typography>
            </div>
        </>
    )
}

export default Hero
