import tuktukVector from '../../assets/images/tuktuk-vector.svg'
import './styles.css'

const BlurImageWrapper = ({ children, imageUrl, renderVector = false }) => (
    <div className="wrapper-container">
        <div
            style={{ '--img': `url(${imageUrl})` }}
            className="bgimg-container">
            {renderVector ? (
                <img
                    src={tuktukVector}
                    alt="tours privados a medida"
                    className="img-vector"
                />
            ) : null}
            {children}
        </div>
    </div>
)

export default BlurImageWrapper
