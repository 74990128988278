import { RouterProvider } from 'react-router-dom'
import { ReactComponent as TuktukLogo } from './assets/images/tuktuk-logo.svg'
import Header from './components/Header/Header.js'
import theme from './theme.js'
import { createTheme, ThemeProvider } from '@mui/material'
import router from './router/router.js';
import pages from './components/MockData/menu.js';
import Footer from './components/Footer'

function App() {
  const customTheme = createTheme(theme)
    return (
        <ThemeProvider theme={customTheme}>
            <Header Logo={TuktukLogo} pages={pages}></Header>
            <RouterProvider router={router} />
            <Footer />
        </ThemeProvider>
    )
}
export default App
