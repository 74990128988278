/* eslint-disable no-undef */
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box
} from '@mui/material';
import './productCard.css';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'

const ProductCard = ({ imageUrl, location, price, rate, reviews, title }) => {
    const image = require(`../../assets/${imageUrl}`)
    return (
        <Card
            id="productCard"
            sx={{
                maxWidth: 340,
                width: { xs: 'auto', md: 340 },
                height: { xs: 380, md: 420 },
                boxShadow: 10,
                borderRadius: 2
            }}>
            <CardMedia
                sx={{ marginBottom: -2 }}
                image={image}
                title={title}
            />
            <Box
                sx={{
                    py: { xs: 0, md: '.8rem' },
                    px: { xs: 0, md: '.8rem' },
                    width: '100%'
                }}>
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            py: '.5rem',
                            px: 0
                        }}>
                        <LocationIcon
                            height={18}
                            width={12}
                            style={{ marginRight: '.5rem' }}
                        />
                        <Typography
                            variant="body2"
                            color="grayText"
                            fontFamily={'Inter'}>
                            {location}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle1">{title}</Typography>
                </CardContent>
                <CardActions sx={{ py: 0 }}>
                    <Box
                        sx={{
                            width: 40,
                            height: 35,
                            borderRadius: 2,
                            bgcolor: 'primary.main',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 0.5,
                            marginBottom: 2
                        }}>
                        <Typography
                            variant="h6"
                            color="primary.contrastText"
                            sx={{ fontWeight: 'bold' }}>
                            {rate}
                        </Typography>
                    </Box>
                    <Typography
                        mb={2}
                        pl={1}
                        fontFamily={'Poppins'}
                        sx={{
                            display: { xs: 'none', md: 'flex' }
                        }}>
                        {`${reviews.state}`}
                    </Typography>
                    <Typography
                        mb={2}
                        fontFamily={'Poppins'}
                        sx={{
                            fontSize: { xs: '14px', md: '16px' }
                        }}>
                        {`(${reviews.number} Reviews)`}
                    </Typography>
                </CardActions>
                <CardActions sx={{ py: 0, ml: '.3rem' }}>
                    <Typography
                        color="primary"
                        sx={{
                            fontSize: { xs: '14px', md: '18px' },
                            fontWeight: { xs: 600, md: 700 }
                        }}>
                        {`${price} €`}
                    </Typography>
                </CardActions>
                <CardActions sx={{ py: 0, ml: '.3rem' }}>
                    <Typography
                        color="grayText"
                        sx={{
                            fontSize: { xs: '10px', md: '12px' },
                            fontWeight: 400
                        }}>
                        IVA incluido
                    </Typography>
                </CardActions>
            </Box>
        </Card>
    )
}

export default ProductCard
