import { Box, Typography, } from "@mui/material"
import { ReactComponent as VisaLogo } from "../../assets/icons/visa.svg";
import { ReactComponent as MarsterCardLogo } from "../../assets/icons/mastercard.svg";
import { ReactComponent as StripeLogo } from "../../assets/icons/stripe.svg";

const PayMethodsSection = () => (
    <Box sx={{mb: "2rem"}}>
        <Typography sx={{
            color: "#666B6E",
            fontSize: "14px",
            textAlign: { xs: "center", md: "left" },
            my: "1rem",
        }}>
            Compromiso de seguridad en pagos electrónicos
        </Typography>
        <Box sx={{
            display: "flex",
            justifyContent: {xs: "space-between", md: "start"},
            px: { xs: "4rem", md: 0 },
            gap: {xs: 0, md: "1rem"}
        }}>
            <VisaLogo />
            <MarsterCardLogo />
            <StripeLogo />
        </Box>
    </Box>

)

export default PayMethodsSection;
