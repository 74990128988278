import React from "react"
import {
    Box,
    Container,
    Divider,
    Link,
    List,
    ListItem,
    Typography,
} from "@mui/material"
import PayMethodsSection from "./PayMethodsSection"
import SocialLinksSection from "./SocialLinksSection"
import FooterImage from "../../assets/images/tuktuk-footer.png"
import { ReactComponent as TukTukLogo } from "../../assets/images/tuktuk-logo.svg"

const DeskopFooter = () => (
    <Box sx={{
        backgroundColor: "#F0F0F1",
        width: "100%"
    }}>
        <Container sx={{
            display: "flex",
            alignItems: "center",
            pt: "6rem",
            pb: "2rem",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "65%",
            }}>
                <TukTukLogo style={{ height: 40, width: 125, marginBottom: ".8rem" }} />
                <Divider aria-hidden="true" color='secondary' sx={{ height: 2, mb: "2rem",  width: 150 }} />
                <Box sx={{
                    color: "#666B6E",
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                    justifyContent: "space-between",
                    mb: "2rem",
                }}>
                    <Box>
                        <Typography color='primary' variant='h5'>
                            Destinos
                        </Typography>
                        <List sx={{padding: 0}}>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum ddolor</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            
                        </List>
                    </Box>
                    <Box>
                        <Typography color='primary' variant='h5'>
                            Guías de viaje
                        </Typography>
                        <List sx={{padding: 0}}>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum ddolor</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            
                        </List>
                    </Box>
                    <Box>
                        <Typography color='primary' variant='h5'>
                            Especialidades
                        </Typography>
                        <List sx={{padding: 0}}>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum ddolor</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Lorem ipsum</Link>
                            </ListItem>
                            
                        </List>
                    </Box>
                    <Box>
                        <Typography color='primary' variant='h5'>
                            Trekzy
                        </Typography>
                        <List sx={{padding: 0}}>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Quiénes somos</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>FAQs</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Políticas de uso</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Política de Privacidad</Link>
                            </ListItem>
                            <ListItem sx={{ padding: 0}}>
                                <Link sx={{py: ".5rem"}} color="grayText" underline='none'>Política de cookies</Link>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Divider aria-hidden="true" color='secondary' sx={{ height: 2, mb: "1rem", width: "100%" }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                    <PayMethodsSection />
                    <SocialLinksSection />
                </Box>
            </Box>
            <Box sx={{width: "35%", display: "flex", justifyContent: "end"}}>
                <img src={FooterImage} style={{ width: "80%", height: "70%" }} />
            </Box>
        </Container>
    </Box>
)

export default DeskopFooter