import React from 'react'
import { ReactComponent as CoinsIcon } from '../../assets/icons/coins.svg'
import { ReactComponent as ClientIcon } from '../../assets/icons/client.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import IconCard from '../IconCard/IconCard'
import { Box, Grid, Typography } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

const cards = [
    {
        title: 'Mejores precios',
        text: 'Lorem ipsum dolor sit amet consectetur. Cras dui aliquam id id mi tincidunt.',
        Icon: CoinsIcon
    },
    {
        title: 'Atención al cliente',
        text: 'Lorem ipsum dolor sit amet consectetur. Cras dui aliquam id id mi tincidunt.',
        Icon: ClientIcon
    },
    {
        title: 'Cancelación gratuita',
        text: 'Lorem ipsum dolor sit amet consectetur. Cras dui aliquam id id mi tincidunt.',
        Icon: CancelIcon
    },
    {
        title: 'Personalización',
        text: 'Lorem ipsum dolor sit amet consectetur. Cras dui aliquam id id mi tincidunt.',
        Icon: EditIcon
    }
]

const CarouselIconCards = ({ title }) => {
    return (
        <Box sx={{ my: '40px' }}>
            <Typography
                color="primary"
                variant="h5"
                component="h3"
                sx={{
                    fontSize: { xs: '20px', md: '24px' },
                    fontWeight: 700,
                    textAlign: 'center'
                }}>
                {title}
            </Typography>
            <Carousel
                autoPlay={false}
                sx={{ mt: '30px', display: { xs: 'block', md: 'none' } }}>
                {cards.map((card) => (
                    <IconCard key={card.title} card={card} />
                ))}
            </Carousel>
            <Grid
                container
                sx={{ mt: '30px', display: { xs: 'none', md: 'flex' } }}>
                {cards.map((card) => (
                    <Grid key={card.title} item xs={12} md={3}>
                        <IconCard card={card} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default CarouselIconCards
