import { createTheme } from '@mui/material'
import { red } from '@mui/material/colors'

const theme = createTheme({
    palette: {
        appBar: {
            main: '#ffffff',
            contrastText: '#ffffff'
        },
        primary: {
            main: '#2B0075',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#02B4BA'
        },
        grayText: {
            main: '#666B6E',
            contrastText: '#ffffff'
        },
        error: {
            main: red.A400,
            contrastText: '#ffffff'
        },
        white: {
            main: '#ffffff',
            contrastText: '#ffffff'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    borderRadius: 50,
                    color: '#fff',
                    textTransform: 'none',
                    fontWeight: 'bold'
                }
            }
        },
        MuiImageListItemBar: {
            styleOverrides: {
                title: {
                    fontSize: '20px'
                },
                titleWrap: {
                    paddingRight: '0px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        fontWeight: 700,
                        padding: '0 1rem',
                        'border-bottom': '2px solid #40C5E8'
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#02B4BA',
                    top: '74px!important',
                    left: '0px!important',
                    borderRadius: '0px!important',
                    minWidth: '100%',
                    minHeight: '100%',
                    width: '100%',
                    height: '100%',
                    paddingTop: '30px'
                }
            }
        }
    },
    typography: {
        fontFamily: ['DM Serif Text, serif, Inter'].join(','),
        h3: {
            fontSize: 32,
            fontFamily: 'DM Serif Text',
            fontWeight: 'bolder',
            lineHeight: 1.5,
            '@media (min-width: 481px)': {
                fontSize: 72
            }
        },
        subtitle: {
            fontSize: 22,
            fontFamily: 'DM Serif Text',
            fontWeight: 'bolder',
            lineHeight: 1.5,
            '@media (min-width: 481px)': {
                fontSize: 32
            }
        },
        subtitle1: {
            fontSize: 16,
            fontFamily: 'DM Serif Text',
            fontWeight: 'bold',
            lineHeight: 1.2,
            '@media (min-width: 481px)': {
                fontSize: 24,
                fontWeight: 700
            }
        },
        subtitle2: {
            fontSize: 22,
            fontFamily: 'DM Serif Text',
            fontWeight: 'bold',
            lineHeight: 1.5,
            '@media (min-width: 481px)': {
                fontSize: 48
            }
        },
        body: {
            fontSize: 18,
            fontFamily: 'DM Serif Text',
            fontWeight: 400,
            lineHeight: 1.5
        },
        body2: {
            fontSize: 12,
            fontFamily: 'DM Serif Text',
            fontWeight: 400,
            lineHeight: 1.5
        }
    }
})

export default theme
