import React from 'react'
import {
    Box,
    Button,
    Grid,
    ImageList,
    ImageListItem,
    Link,
    Typography
} from '@mui/material'
import Tabs from './Tabs'
import ProductCard from '../ProductCard'
import { useScreenDetector } from '../../hooks/useScreenDetector'

const CardsTab = ({ tabData }) => {
    const { isMobile } = useScreenDetector()
    const initialCards = isMobile
        ? tabData[0].data.slice(0, 4)
        : tabData[0].data

    const [activeTabIndex, setActiveTabIIndex] = React.useState(0)
    const [currentCards, setCurrentCards] = React.useState(initialCards)

    const onChangeTab = (tabIndex) => (evt, value) => {
        const tab = tabIndex !== undefined ? tabIndex : value
        setActiveTabIIndex(tab)
        if (isMobile) {
            const mobileCards = tabData[tab].data.slice(0, 4)
            setCurrentCards([...mobileCards])
        } else {
            setCurrentCards([...tabData[tab].data])
        }
    }

    return (
        <Box>
            <Grid container mb={1.5}>
                <Grid item xs={12} md={9}>
                    <Typography
                        color="primary"
                        variant="subtitle"
                        component="h3"
                        sx={{ fontSize: { xs: '20px', md: '32px' } }}>
                        Nuestros destinos Favoritos
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        href="/destinos"
                        sx={{
                            borderRadius: '16px',
                            height: 'fit-content',
                            color: '#ffffff',
                            display: 'grid',
                            textTransform: 'none'
                        }}>
                        Ver más destinos
                    </Button>
                </Grid>
            </Grid>
            <Tabs
                tabs={tabData}
                activeTab={activeTabIndex}
                onChangeTab={onChangeTab}
            />
            {isMobile ? (
                <Grid container spacing={1} sx={{ my: '1.5rem' }}>
                    {currentCards.map((tab, index) => (
                        <Grid key={`${tab.title}${index}`} item xs={6}>
                            <ProductCard {...tab} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <ImageList
                    sx={{
                        gridAutoFlow: 'column',
                        overflowX: 'scrollable',
                        pt: '1rem',
                        pb: '4rem'
                    }}>
                    {currentCards.map((tab, index) => (
                        <ImageListItem
                            key={`${tab.title}${index}`}
                            sx={{
                                marginRight: '1rem'
                            }}>
                            <ProductCard {...tab} />
                        </ImageListItem>
                    ))}
                </ImageList>
            )}
            <Box
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'center',
                    my: '3rem'
                }}>
                <Link
                    color="secondary"
                    underline="none"
                    sx={{
                        fontSize: 20,
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        lineHeight: 1.5
                    }}>
                    Ver más destinos
                </Link>
            </Box>
        </Box>
    )
}

export default CardsTab
