/* eslint-disable no-undef */

const mountainTours = [
    {
        imageUrl: 'images/apalaches.png',
        ivaIncluded: true,
        location: 'Georgia, Estados Unidos',
        price: 150.5,
        rate: 9.2,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Sendero de los Apalaches'
    },
    {
        imageUrl: 'images/fimmvorouhals.png',
        ivaIncluded: true,
        location: 'Islandia',
        price: 150.5,
        rate: 8.9,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Paso de Fimmvörðuháls'
    },
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        location: 'Hawái, Estados Unidos',
        rate: 9,
        price: 150.5,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        title: 'Sendero Kalalau y gastronomía local'
    }
]

const cityTours = [
    {
        imageUrl: 'images/apalaches.png',
        ivaIncluded: true,
        location: 'Estambul, Turquía',
        price: 150.5,
        rate: 7.4,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Estambul nocturno: Sofía y la Mezquita Azul'
    },
    {
        imageUrl: 'images/fimmvorouhals.png',
        ivaIncluded: true,
        location: 'Fez, Marruecos',
        price: 150.5,
        rate: 8.9,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Descubre el mercado de especias en Fez'
    },
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        location: 'París, Francia',
        rate: 9,
        price: 150.5,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        title: 'Grandes museos de París: Pintura y antigüedad'
    }
]

export const getCardsByTours = (name) => {
    switch (name) {
        case 'Montaña':
            return [...mountainTours, ...mountainTours]
        case 'Ciudad':
            return [...cityTours, ...cityTours]
        default:
            return [...mountainTours, ...cityTours]
    }
}
