import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg'
import { ReactComponent as SearchOpenIcon } from '../../assets/icons/search-open.svg'
import { ReactComponent as CloseCircle } from '../../assets/icons/close-circle.svg'
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Link,
    Collapse
} from '@mui/material'
import SearchInput from '../SearchInput'
import { useScreenDetector } from '../../hooks/useScreenDetector'

const Header = ({ Logo, pages }) => {
    const [openSearch, setOpenSearch] = useState(false)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const { isMobile } = useScreenDetector()

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleOpenSearch = () => {
        setOpenSearch(!openSearch)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    return (
        <Box>
            <AppBar
                color="appBar"
                sx={{
                    px: { xs: '1%', md: '6%', lg: '8%' },
                    py: '8px',
                    position: 'initial'
                }}>
                <Toolbar sx={{}}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'contents', md: 'none' }
                        }}>
                        {isMobile && (
                            <>
                                <IconButton
                                    sx={{ color: 'secondary.main' }}
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}>
                                    <MenuIcon />
                                </IconButton>
                                {Boolean(anchorElNav) && (
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        keepMounted
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}>
                                        {pages.map((page) => (
                                            <MenuItem key={page.text}>
                                                <Link
                                                    href={page.url}
                                                    underline="none">
                                                    <Typography
                                                        color="primary.contrastText"
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                            lineHeight: '21px'
                                                        }}>
                                                        {page.text}
                                                    </Typography>
                                                </Link>
                                            </MenuItem>
                                        ))}
                                        <MenuItem
                                            onClick={handleCloseNavMenu}
                                            sx={{ justifyContent: 'center' }}>
                                            <CloseCircle
                                                style={{
                                                    width: 30,
                                                    height: 30
                                                }}
                                            />
                                        </MenuItem>
                                    </Menu>
                                )}
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'flex-start'
                        }}>
                        <Link href="/">
                            <Logo style={{ width: 60, height: 58 }} />
                        </Link>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center'
                        }}>
                        {pages.map((page) => (
                            <MenuItem
                                key={page}
                                sx={{
                                    my: 2,
                                    px: '24px',
                                    color: 'white',
                                    display: 'block'
                                }}>
                                {' '}
                                <Link href={page.url} underline="none">
                                    <Typography
                                        color="primary"
                                        textAlign="center"
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            lineHeight: '21px'
                                        }}>
                                        {page.text}
                                    </Typography>
                                </Link>
                            </MenuItem>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            right: 0
                        }}>
                        <IconButton
                            aria-label="login"
                            sx={{ px: { xs: '6px', md: '12px' } }}>
                            <UserIcon style={{ width: 22, height: 22 }} />
                            <Typography
                                color="secondary"
                                textAlign="center"
                                sx={{
                                    pt: '5px',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '21px',
                                    display: {
                                        xs: 'none',
                                        md: 'block',
                                        lg: 'block'
                                    }
                                }}>
                                Log in
                            </Typography>
                        </IconButton>
                        <IconButton
                            onClick={handleOpenSearch}
                            aria-label="search"
                            sx={{ px: { xs: '6px', md: '12px' } }}>
                            {openSearch ? (
                                <SearchOpenIcon
                                    style={{ width: 36, height: 36 }}
                                />
                            ) : (
                                <SearchIcon style={{ width: 36, height: 36 }} />
                            )}
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            sx={{ px: { xs: '6px', md: '12px' } }}>
                            <CartIcon style={{ width: 38, height: 38 }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Collapse in={openSearch}>
                <Box sx={{ bgcolor: 'primary.main', display: 'ruby-text' }}>
                    <SearchInput />
                </Box>
            </Collapse>
        </Box>
    )
}

export default Header
