import React from 'react'

export const useScreenDetector = () => {
    const [width, setWidth] = React.useState(window.innerWidth)

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const isMobile = width <= 768
    const isTablet = width <= 1024
    const isDesktop = width > 1024

    return { isMobile, isTablet, isDesktop }
}
