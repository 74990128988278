import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput
} from '@mui/material'
import { ReactComponent as SearchIcon } from '../../assets/icons/search-fill.svg'

const SearchInput = () => (
    <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 650,
            width: '100%'
        }}>
        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
                placeholder="Busca un destino o tour"
                type={'text'}
                sx={{
                    borderRadius: 30,
                    paddingLeft: 1,
                    paddingRight: 2,
                    backgroundColor: '#ffffff'
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="search-input"
                            onClick={() => {}}
                            onMouseDown={() => {}}
                            edge="end">
                            <SearchIcon sx={{ width: 36, height: 36 }} />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    </Box>
)

export default SearchInput
