
const content = {
    title: {
        value: 'Lorem ipsum dolor sit amet',
        variant: 'h1'
    },
    paraghrap: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.Lorem ipsum dolor sit amet consectetur.
    Placerat feugiat volutpat lorem tincidunt lectus sapien. Libero sapien donec in congue lacus ornare consequat.
    Nulla risus.Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    image: {
        src: require('../../assets/images/barcelona.jpg'),
        alt: 'Barcelona',
        height: '400px',
        width: '600px'
    }
}

export default content;