const dataCarousel = {
  title1: 'Testimonios',
  title2: 'Lo que opinan nuestros clientes',
  text: `Lorem ipsum dolor sit amet consectetur. Enim quis in eu leo laoreet habitant tincidunt eget quam. 
  Lorem ipsum dolor sit amet consectetur. Enim qu.`,
  reviews: [{
    nameCharacters: 'AS',
    rating: 1,
    title: 'Lorem ipsum dolor amet1',
    text: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    date: '20/05/2024'
  },{
    nameCharacters: 'DS',
    rating: 4,
    title: 'Lorem ipsum dolor amet2',
    text: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    date: '20/05/2024'
  },{
    nameCharacters: 'FS',
    rating: 3,
    title: 'Lorem ipsum dolor amet3',
    text: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    date: '20/05/2024'
  },{
    nameCharacters: 'HS',
    rating: 5,
    title: 'Lorem ipsum dolor amet4',
    text: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    date: '20/05/2024'
  },{
    nameCharacters: 'GS',
    rating: 2,
    title: 'Lorem ipsum dolor amet5',
    text: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    date: '20/05/2024'
  },{
    nameCharacters: 'KS',
    rating: 4,
    title: 'Lorem ipsum dolor amet6',
    text: `Lorem ipsum dolor sit amet consectetur. Placerat feugiat volutpat lorem tincidunt lectus sapien. 
    Libero sapien donec in congue lacus ornare consequat. Nulla risus.`,
    date: '20/05/2024'
  }],
  footer: {
    title: '¿Necesitas ayuda?',
    text: 'Lorem ipsum dolor sit amet consectetur. Orci.',
    buttonLabel: 'Contacta con TUK TUK',
    buttonUrl: '#'
  }
};

export default dataCarousel;