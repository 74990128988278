import { createBrowserRouter } from "react-router-dom";
import Destinations from "../pages/Destinations";
import Home from "../pages/Home";
import Blog from "../pages/Blog";
import Destination from "../pages/Destination";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
        path: 'destinos',
        element: <Destinations />
    },
    {
        path: 'blog',
        element: <Blog />
    },
    {
        path: 'destino/:destinoId',
        element: <Destination />
    }
]);

export default router;