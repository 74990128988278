import React from 'react'
import { Container } from '@mui/material'
import CardsTab from '../../components/CardsTab'
import GuidesBanner from '../../components/GuidesBanner'
import Hero from '../../components/Hero'
import { getCardsByTours } from '../../utils/data'
import './styles.css'
import CarouselIconCards from '../../components/CarouselIconCards/CarouselIconCards'
import gridImagesContent from '../../components/MockData/gridImages'
import ReviewsCarousel from '../../components/ReviewsCarousel/ReviewsCarousel'
import dataCarousel from '../../components/MockData/reviewsCarousel'
import ImagesGrid from '../../components/ImagesGrid'

const carouselTitle = '¿Por qué elegir a TUK TUK?'

const Home = () => {
    const cardTabs = [
        {
            name: 'Playa',
            data: [...getCardsByTours('Playa')]
        },
        {
            name: 'Montaña',
            data: [...getCardsByTours('Montaña')]
        },
        {
            name: 'Ciudad',
            data: [...getCardsByTours('Ciudad')]
        },
        {
            name: 'Rural',
            data: [...getCardsByTours('Rural')]
        }
    ]

    return (
        <>
            <Hero />
            <Container>
                <section className="section">
                    <CarouselIconCards title={carouselTitle} />
                </section>
                <section className="section">
                    <ImagesGrid {...gridImagesContent} />
                </section>
                <section className="section">
                    <CardsTab tabData={cardTabs} />
                </section>
            </Container>
            <section>
                <GuidesBanner />
            </section>
            <section>
                <ReviewsCarousel data={dataCarousel} />
            </section>
        </>
    )
}

export default Home
