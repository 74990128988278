import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const IconCard = ({ card }) => {
    var { Icon, title, text } = card
    return (
        <Card
            sx={{
                position: 'initial',
                boxShadow: 'none',
                borderRadius: 'unset'
            }}>
            <Box
                sx={{
                    textAlign: 'center'
                }}>
                <Icon style={{ width: 50, height: 50 }} />
            </Box>
            <CardContent>
                <Typography
                    gutterBottom
                    component="div"
                    sx={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700
                    }}>
                    {title}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                        textAlign: 'center',
                        fontWeight: 400,
                        lineHeight: '24px'
                    }}>
                    {text}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default IconCard
