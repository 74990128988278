import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ReviewCard from "../ReviewCard/ReviewCard";
import {ReactComponent as Left} from "../../assets/icons/left.svg";
import {ReactComponent as Right} from "../../assets/icons/right.svg";
import { useLayoutEffect, useRef, useState } from "react";



const ReviewsCarousel = ({data}) => {
  const {reviews, title1, title2, text, footer} = data;
  const [middleCard, setMiddleCard] = useState(Math.trunc(reviews.length/2));
  const [widthContainer, setWidthContainer] = useState(0);
  const ref = useRef(null);
  var widthCard = 330, zoomCard = 100, countZoomCard = 0;

  const getPositionCard = (middleCard, initialcard, widthCard, positionCard) => {
    //var positionCard = 400;
    var countScale = 0;

    if(middleCard == initialcard){
      return positionCard;
    } 

    if(initialcard < middleCard) {
      for(let i = middleCard; i > initialcard; i--) {
        positionCard = (positionCard - ((widthCard - countScale)));
        countScale =+ 33;
      }
    } else {
      for(let i = middleCard; i < initialcard; i++) {
        positionCard = (positionCard + ((widthCard - countScale)));
        countScale =+ 33
      }
    }
    
    return positionCard;
  }
  
  const handleLeft = () => {
    setMiddleCard(middleCard == 1 ? 1 : middleCard - 1);
  }

  const handleRight = () => {
    setMiddleCard(middleCard == reviews.length ? reviews.length : middleCard + 1);
  }

  useLayoutEffect(() => {
    setWidthContainer(ref.current.offsetWidth);
  }, []);

  return (
      <Box sx={{
            pt: '60px', 
            backgroundColor: 'primary.main', 
            textAlign: 'center', 
            mb: '60px'
          }}
        >
        <Box sx={{px: '15px'}}>
          <Typography 
            fontFamily={"Poppins"} 
            color="secondary" 
            variant="h5" 
            component="h5" 
            sx={{
              fontWeight: 600,
              fontSize: '1.1rem'
              }}>
            {title1}
          </Typography>
          <Typography 
            fontFamily={"Poppins"}
            color="primary.contrastText"
            variant="h3" 
            component="h3" 
            sx={{
              fontWeight: 600,
              fontSize: {xs: '1.4rem', md: '1.7rem'},
              pb: '10px'
              }}>
            {title2}
          </Typography>
          <Typography  
            color="primary.contrastText"
            variant="body2" 
            component="p" 
            sx={{
              fontSize: {xs: '.8rem', md: '.9rem'},
              }}>
            {text}
          </Typography>
        </Box>
        <Box ref={ref} sx={{
          overflow: 'hidden',
          width: '100%', 
          height: '300px', 
          position: 'relative', 
          display: 'flex', 
          alignItems: 'center',
          pt: '40px'
        }}>
          {reviews.map((review, index) => {
              countZoomCard = Math.abs(((middleCard - (index + 1)) * 10));
              let position = widthContainer <= widthCard ? 0 : (widthContainer/2) - (widthCard/2);
              return (<ReviewCard 
                        key={review.title} 
                        card={review} 
                        positionCard={`${getPositionCard(middleCard, index + 1, widthCard, position)}px`} 
                        zoomCard={`${zoomCard - countZoomCard}%`} 
                      />)
          })}
        </Box>
        <Box sx={{textAlign: 'center', mb: '50px'}}>
          <IconButton 
            onClick={handleLeft} 
            aria-label="left" 
            sx={{p: '0px'}}
          >
            <Left style={{ width: 44, height: 44 }} />
          </IconButton >
          <IconButton 
            onClick={handleRight} 
            aria-label="right" 
            sx={{p: '0px'}}
          >
            <Right style={{ width: 44, height: 44 }} />
          </IconButton>
        </Box>
        <Grid 
          container 
          sx={{
            bgcolor: 'secondary.main', 
            mx: '5%', 
            width: 'auto',
            px: '6%',
            py: '20px',
            borderRadius: '6px',
            position: 'relative',
            top: '50px'

          }}
        >
          <Grid 
            item 
            xs={12} 
            md={9}
            sx={{
              textAlign: {sx: 'center', md: 'left'},
              pb: '10px'
            }}
          >
            <Typography 
              fontFamily={"Poppins"}
              color="primary.contrastText"
              variant="h5" 
              component="h3" 
              sx={{
                fontSize: {xs: '16px', md: '20px'}, 
                fontWeight: 700
              }
              }>
                {footer.title}
            </Typography>
            <Typography 
              color="primary.contrastText"
              variant="body1" 
              component="p"
              sx={{fontSize: '13px'}}
            >
                {footer.text}
            </Typography>
          </Grid>
          <Grid 
            item 
            xs={12}
            md={3}
            alignItems='center'
            justifyContent='flex-end'
          >
              <Button 
                fontFamily={"Poppins"}
                variant="contained" 
                color="primary"
                href={footer.buttonUrl} 
                sx={{
                  borderRadius: '16px', 
                  height: 'fit-content', 
                  color: 'primary.contrastText', 
                  display: 'grid', 
                  textTransform: 'none',
                  fontWeight: 600,
                }}
              >
                {footer.buttonLabel}
              </Button>
          </Grid>
        </Grid>
      </Box>
  )
}

export default ReviewsCarousel