import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

const Destination = () => {
    let params = useParams();
    console.log(params);
    return (
        <Container>
            <h1>{params.destinoId}</h1>
        </Container>
    )
}

export default Destination;