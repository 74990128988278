import { Avatar, Box, Card, CardContent, Rating, Typography } from "@mui/material";


const ReviewCard = ({card, positionCard, zoomCard}) => {
    var {nameCharacters, rating, title, text, date} = card;
    
    return(
        <Card sx={{ 
                    position:'absolute', 
                    overflow: 'visible', 
                    height: '220px', 
                    width: '330px', 
                    left: positionCard, 
                    transform:`scale(${zoomCard})`,
                    transition: 'left 1s, transform 1s',
                    borderRadius: '10px'
                }}>
            <Box sx={{
                position: 'relative',
                left: '45%',
                top: '-20px',
                width: 'fit-content'
            }}>
                <Avatar  sx={{bgcolor: 'secondary.main', fontSize: '15px', fontWeight: 700}} >{nameCharacters}</Avatar>
            </Box>
            <CardContent sx={{py: '0px'}}>
                <Typography 
                    fontFamily={"Poppins"}
                    color='primary' 
                    gutterBottom component="div" 
                    sx={{textAlign: 'center', 
                    fontSize: '14px', 
                    fontWeight: 600,
                }}>
                    {title}
                </Typography>
                <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{textAlign: 'center', 
                    fontSize: '12px', 
                    fontWeight: 500, 
                    lineHeight: '17px'
                }}>
                    {text}
                </Typography>
                <Box sx={{
                    textAlign: '-webkit-center',
                    mb: '10px',
                }}>
                    <Box sx={{
                        background: '#F0F0F1', 
                        mt: '10px', 
                        width: 'fit-content', 
                        height: '20px', 
                        px: '5px', 
                        borderRadius: '12px', 
                        lineHeight: .75
                    }}>
                        <Rating 
                            name="read-only" 
                            value={rating} 
                            max={rating} 
                            size="small" 
                            readOnly 
                        />
                    </Box>
                </Box>
                <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{textAlign: 'center', 
                    fontWeight: 400, 
                    lineHeight: '24px'
                }}>
                    {date}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default ReviewCard;