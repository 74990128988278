import { Container } from "@mui/material";

const Destinations = () => {
    return (
        <Container>
            <h1>Destinations</h1>
        </Container>
    )
}

export default Destinations;